<template>
    <div>
      <div class="mt-8">
        <div class="flex flex-wrap justify-between items-center">
          <div class="w-72 mb-4">
            <SearchTextInput @searchCall="searchCall" :placeholderText="`Search Payment...`"/>
          </div>
        </div>
      </div>
      <div>
        <div style="overflow-y: auto; white-space: nowrap;" class="">
          <div class="text-gray4  min-w-min border-b border-gray1 font-bold flex heading-7  px-3 py-3 items-center">
            <div class="table_col w-52 uppercase  flex items-center" :class="titleObj[0].icon !== '' ? 'text-primary' : ''" @click="sortByColumn(0)">
              <span class="cursor-pointer">{{titleObj[0].title}}</span>
               <div v-if="titleObj[0].icon !== ''">
                  <descIconSvg v-if="titleObj[0].icon === 'DESC'" class="ml-2" :width="'20px'" :height="'20px'" />
                  <ascIconSvg v-else class="ml-2" :width="'20px'" :height="'20px'" />
                </div>
            </div>
            <div class="table_col w-52 uppercase  flex items-center" :class="titleObj[1].icon !== '' ? 'text-primary' : ''" @click="sortByColumn(1)">
              <span class="cursor-pointer">{{titleObj[1].title}}</span>
               <div v-if="titleObj[1].icon !== ''">
                  <descIconSvg v-if="titleObj[1].icon === 'DESC'" class="ml-2" :width="'20px'" :height="'20px'" />
                  <ascIconSvg v-else class="ml-2" :width="'20px'" :height="'20px'" />
                </div>
            </div>
            <div class="table_col w-52 uppercase  flex items-center" :class="titleObj[2].icon !== '' ? 'text-primary' : ''" @click="sortByColumn(2)">
              <span class="cursor-pointer">{{titleObj[2].title}}</span>
               <div v-if="titleObj[2].icon !== ''">
                  <descIconSvg v-if="titleObj[2].icon === 'DESC'" class="ml-2" :width="'20px'" :height="'20px'" />
                  <ascIconSvg v-else class="ml-2" :width="'20px'" :height="'20px'" />
                </div>
            </div>
            <div class="table_col w-44 uppercase  flex items-center" :class="titleObj[3].icon !== '' ? 'text-primary' : ''" @click="sortByColumn(3)">
              <span class="cursor-pointer">{{titleObj[3].title}}</span>
               <div v-if="titleObj[3].icon !== ''">
                  <descIconSvg v-if="titleObj[3].icon === 'DESC'" class="ml-2" :width="'20px'" :height="'20px'" />
                  <ascIconSvg v-else class="ml-2" :width="'20px'" :height="'20px'" />
                </div>
            </div>
            <div class="table_col w-44 uppercase  flex items-center" :class="titleObj[4].icon !== '' ? 'text-primary' : ''" @click="sortByColumn(4)">
              <span class="cursor-pointer">{{titleObj[4].title}}</span>
               <div v-if="titleObj[4].icon !== ''">
                  <descIconSvg v-if="titleObj[4].icon === 'DESC'" class="ml-2" :width="'20px'" :height="'20px'" />
                  <ascIconSvg v-else class="ml-2" :width="'20px'" :height="'20px'" />
                </div>
            </div>
          </div>
            <div v-if="paymentList.length > 0">
            <div v-for="(data, index) in paymentList" :key="index"  class="min-w-min flex heading-5 border-b border-gray1  bg-white  text-sm px-3 py-3 items-center hover:bg-gray1 cursor-pointer" @click="openPaymentDetails(data.paymentId)">
              <div class="table_col w-52 ">
                <div class="">
                <p class="  text-text2 font-bold">{{data.companyName !== '' ? data.companyName : '--'}}</p>
                </div>
              </div>
              <div class="table_col w-52 ">
                <div class="flex items-center">
                <span class="  text-gray4 heading-6">{{data.transactionId !== '' ? data.transactionId : '--'}}</span>
                </div>
              </div>
              <div class="table_col w-52">
                <div class="flex items-center">
                <span class="  text-gray4 heading-6">{{data.paymentMethodName !== '' ? data.paymentMethodName : '--'}}</span>
                </div>
              </div>
              <div class="table_col w-44 ">
                <div class="flex items-center">
                <span class="  text-gray4 heading-6">{{data.paymentAmount | amountOnlyNumber}}</span>
                </div>
              </div>
              <div class="table_col w-44 ">
                <div class="flex items-center">
                <span :style="`color: ${data.invoiceStatusColor};`" class=" text-gray4 heading-6">{{data.addedDate | formatForDatePicker}}</span>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <NoRecords :alertMessage="` No records found, please check filters on this page in case you did not find required data.`" />
          </div>
          <div>
          <Pagination
                :totalDataCount="totalInvoice"
                :resetPagination="resetPaginationValues"
                @callApiAsPerPagination="getDataFromPagination"/>
        </div>
        </div>
      </div>
    </div>
</template>
<script>
// import StatusBtn from "@/View/components/statu-filter-btn.vue";
import SearchTextInput from '@/View/components/searchTextInput.vue'
// import Button from '@/View/components/globalButton.vue'
import ADMINAPI from '@/View/Admin/api/Admin.js'
import NoRecords from '@/View/components/noRecords.vue'
import Pagination from '@/View/components/pagination.vue'
import deboucneMixin from '@/mixins/debounce.js'
import ascIconSvg from '@/assets/images/svg/ascIconSvg.vue'
import descIconSvg from '@/assets/images/svg/descIconSvg.vue'
export default {
  components: {
    ascIconSvg,
    NoRecords,
    descIconSvg,
    Pagination,
    SearchTextInput,
    // Button,
    // StatusBtn,
  },
  props: ["orgId"],
  mixins: [deboucneMixin],
  data () {
    return {
        totalInvoice: 0,
        resetPaginationValues: false,
        filterObject: {
          start: 0,
          length: 20,
          sortOrder: 'DESC',
          searchVal: '',
          isActive: true,
          sortBy: 'invoiceDate',
          statusList: []
        },
        paymentList: [],
        titleObj: [
          {title: 'Compnay Name', icon: '', sortName: ''},
          {title: 'Transaction Id', icon: '', sortName: ''},
          {title: 'Payment Method', icon: '', sortName: ''},
          {title: 'Amount', icon: '', sortName: ''},
          {title: 'Date', icon: '', sortName: ''},
        ] 
    }
  },
  mounted () {
    document.title = 'Payment List'
    this.getInvoicesList()
     this.getListDeboucne = this.debounce(function () {
      this.resetPaginationValues = true
    }, 500)
  },
  watch: {
  },
  methods: {
     getDataFromPagination (data) {
      this.filterObject.start = data.offset
      this.filterObject.length = data.limit
      
      this.getInvoicesList(true)
    },
    sortByColumn () {},
    searchCall (data) {
      this.filterObject.searchVal = data
      this.getListDeboucne()
    },
    openPaymentDetails (id) {
      this.$router.push({name: 'PaymentDetail', params: {paymentId: id}})
    },
    getInvoicesList() {
      let isValid = true
      console.log("call getInvoicesList", this.$route);
      if (isValid) {
        this.$store.dispatch("SetLoader", {status: true,message: "Loading...",});
        ADMINAPI.getPaymentList(
          this.filterObject.start,
          this.filterObject.length,
          this.filterObject.searchVal,
          '',
          '',
          parseInt(this.$route.params.customerId),
          '',
          this.orgId,
          (response) => {
            this.resetPaginationValues = false
            this.paymentList = response.Data.tableRow !== null ? response.Data.tableRow  : []
            console.log("response getInvoicesList", response);
            this.$store.dispatch("SetLoader", { status: false, message: "" });
          },
          (err) => {
            this.$store.dispatch('SetAlert', {showAlert: true, message: err.message, color: 'error'})
            this.resetPaginationValues = false
            this.$store.dispatch("SetLoader", { status: false, message: "" });
          }
        );
      }
    },
  }
}
</script>
<style scoped>
.table_col {
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
</style>
