<template>
    <div class="">
      <div class="mt-8">
        <div class="flex flex-wrap mb-4">
           <div class="" v-for="(status, index) in statusList" :key="index" >
              <StatusBtn class=" whitespace-nowrap" @buttonAction="toggleActiveAndInactive(index)" :selected="status.selected" :btnText="status.statusName" :lastInlist="statusList.length === index + 1"/>     
          </div> 
        </div>
        <div class="flex flex-wrap justify-between items-center">
          <div class="w-72 mb-4">
            <SearchTextInput @searchCall="searchCall" :placeholderText="`Search Invoice...`"/>
          </div>
        </div>
      </div>
      <div>
        <div style="overflow-y: auto; white-space: nowrap;" class="">
          <div class="text-gray4  min-w-min border-b border-gray1 font-bold flex heading-7  px-3 py-3 items-center">
            <div class="table_col w-12 uppercase  flex items-center" :class="titleObj[0].icon !== '' ? 'text-primary' : ''" @click="sortByColumn(0)">
              <!-- <span class="cursor-pointer">{{titleObj[0].title}}</span> -->
              <span class="cursor-pointer">#</span>
               <div v-if="titleObj[0].icon !== ''">
                  <descIconSvg v-if="titleObj[0].icon === 'DESC'" class="ml-2" :width="'20px'" :height="'20px'" />
                  <ascIconSvg v-else class="ml-2" :width="'20px'" :height="'20px'" />
                </div>
            </div>
            <div class="table_col w-52 uppercase  flex items-center" :class="titleObj[1].icon !== '' ? 'text-primary' : ''" @click="sortByColumn(1)">
              <span class="cursor-pointer">{{titleObj[1].title}}</span>
               <div v-if="titleObj[1].icon !== ''">
                  <descIconSvg v-if="titleObj[1].icon === 'DESC'" class="ml-2" :width="'20px'" :height="'20px'" />
                  <ascIconSvg v-else class="ml-2" :width="'20px'" :height="'20px'" />
                </div>
            </div>
            <div class="table_col w-52 uppercase  flex items-center" :class="titleObj[2].icon !== '' ? 'text-primary' : ''" @click="sortByColumn(2)">
              <span class="cursor-pointer">{{titleObj[2].title}}</span>
               <div v-if="titleObj[2].icon !== ''">
                  <descIconSvg v-if="titleObj[2].icon === 'DESC'" class="ml-2" :width="'20px'" :height="'20px'" />
                  <ascIconSvg v-else class="ml-2" :width="'20px'" :height="'20px'" />
                </div>
            </div>
            <div class="table_col w-52 uppercase  flex items-center" :class="titleObj[3].icon !== '' ? 'text-primary' : ''" @click="sortByColumn(3)">
              <span class="cursor-pointer">{{titleObj[3].title}}</span>
               <div v-if="titleObj[3].icon !== ''">
                  <descIconSvg v-if="titleObj[3].icon === 'DESC'" class="ml-2" :width="'20px'" :height="'20px'" />
                  <ascIconSvg v-else class="ml-2" :width="'20px'" :height="'20px'" />
                </div>
            </div>
            <div class="table_col w-44 uppercase  flex items-center" :class="titleObj[4].icon !== '' ? 'text-primary' : ''" @click="sortByColumn(4)">
              <span class="cursor-pointer">{{titleObj[4].title}}</span>
               <div v-if="titleObj[4].icon !== ''">
                  <descIconSvg v-if="titleObj[4].icon === 'DESC'" class="ml-2" :width="'20px'" :height="'20px'" />
                  <ascIconSvg v-else class="ml-2" :width="'20px'" :height="'20px'" />
                </div>
            </div>
            <div class="table_col w-44 uppercase  flex items-center" :class="titleObj[5].icon !== '' ? 'text-primary' : ''" @click="sortByColumn(5)">
              <span class="cursor-pointer">{{titleObj[5].title}}</span>
               <div v-if="titleObj[5].icon !== ''">
                  <descIconSvg v-if="titleObj[5].icon === 'DESC'" class="ml-2" :width="'20px'" :height="'20px'" />
                  <ascIconSvg v-else class="ml-2" :width="'20px'" :height="'20px'" />
                </div>
            </div>
          </div>
            <div v-if="invoiceList.length > 0">
            <div v-for="(data, index) in invoiceList" :key="index"  class="min-w-min flex heading-5 border-b border-gray1  bg-white  text-sm px-3 py-3 items-center hover:bg-gray1 cursor-pointer" @click="openInvoiceDetails(data.invoiceId)">
              <div class="table_col w-12 ">
                <div class="">
                <p class="  text-gray4 heading-6 "># {{data.invoiceNumber}}</p>
                </div>
              </div>
              <div class="table_col w-52 ">
                <div class="">
                <p class="  text-text2 font-bold">{{data.companyName}}</p>
                </div>
              </div>
              <div class="table_col w-52 ">
                <div class="flex items-center">
                <span class="  text-gray4 heading-6">{{data.invoiceDate | formatForDatePicker}}</span>
                </div>
              </div>
              <div class="table_col w-52">
                <div class="flex items-center">
                <span class="  text-gray4 heading-6">{{data.dueDate | formatForDatePicker}}</span>
                </div>
              </div>
              <div class="table_col w-44 ">
                <div class="flex items-center">
                <span class="  text-gray4 heading-6">{{data.grandTotal | amountOnlyNumber}}</span>
                </div>
              </div>
              <div class="table_col w-44 ">
                <div class="flex items-center">
                <span :style="`color: ${data.invoiceStatusColor};`" class=" text-gray4 heading-6">{{data.invoiceStatusName}}</span>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <NoRecords :alertMessage="` No records found, please check filters on this page in case you did not find required data.`" />
          </div>
          <div>
          <Pagination
                :totalDataCount="totalInvoice"
                :resetPagination="resetPaginationValues"
                @callApiAsPerPagination="getDataFromPagination"/>
        </div>
        </div>
      </div>
    </div>
</template>
<script>
import StatusBtn from "@/View/components/statu-filter-btn.vue";
import SearchTextInput from '@/View/components/searchTextInput.vue'
import ADMINAPI from '@/View/Admin/api/Admin.js'
import NoRecords from '@/View/components/noRecords.vue'
import Pagination from '@/View/components/pagination.vue'
import deboucneMixin from '@/mixins/debounce.js'
import ascIconSvg from '@/assets/images/svg/ascIconSvg.vue'
import descIconSvg from '@/assets/images/svg/descIconSvg.vue'
export default {
  components: {
    ascIconSvg,
    NoRecords,
    descIconSvg,
    Pagination,
    SearchTextInput,
    StatusBtn,
  },
  props: ["orgId"],
  mixins: [deboucneMixin],
  data () {
    return {
        totalInvoice: 0,
        resetPaginationValues: false,
        statusList: [
          { statusId: 1, statusName: 'Open', selected: true},
          { statusId: 2, statusName: 'Overdue', selected: true},
          { statusId: 3, statusName: 'Partially Paid', selected: true},
          { statusId: 4, statusName: 'Paid', selected: true},
        ],
        filterObject: {
          start: 0,
          length: 20,
          sortOrder: 'DESC',
          searchVal: '',
          isActive: true,
          sortBy: 'invoiceDate',
          statusList: []
        },
        invoiceList: [],
        titleObj: [
          {title: 'Invoice Number', icon: '', sortName: 'invoiceNumber'},
          {title: 'Compnay Name', icon: '', sortName: 'companyName'},
          {title: 'Invoice Date', icon: '', sortName: 'invoiceDate'},
          {title: 'Due Date', icon: 'DESC', sortName: 'dueDate'},
          {title: 'Toal Amount', icon: '', sortName: 'grandTotal'},
          {title: 'Status', icon: '', sortName: 'invoiceStatusName'},
        ] 
    }
  },
  mounted () {
    document.title = 'Invoice List'
    this.getInvoicesList()
     this.getListDeboucne = this.debounce(function () {
      this.resetPaginationValues = true
    }, 500)
  },
  watch: {
  },
  methods: {
     getDataFromPagination (data) {
      this.filterObject.start = data.offset
      this.filterObject.length = data.limit
      
      this.getInvoicesList(true)
    },
    sortByColumn (index) {
      console.log(index);
      this.titleObj.forEach((el, i) => {
        if (i === index) {
          if (el.icon === '') {
            el.icon = 'DESC'
            this.filterObject.sortOrder = 'DESC'
          } else if (el.icon === 'DESC') {
            el.icon = 'ASC'
            this.filterObject.sortOrder = 'ASC'
          } else {
            el.icon = 'DESC'
            this.filterObject.sortOrder = 'DESC'
          }
        } else {
          el.icon = ''
        }
      })
      this.filterObject.sortBy = this.titleObj[index].sortName
      this.getInvoicesList(true)
    },
    searchCall (data) {
      this.filterObject.searchVal = data
      this.getListDeboucne()
    },
    openInvoiceDetails (invoiceId) {
      this.$router.push({name: 'InvoiceDetail', params: {invoiceId: invoiceId}, query: {parentDetaiId: parseInt(this.$route.params.customerId), fromCall: 'customer'}})
    },
    toggleActiveAndInactive (index) {
      this.statusList[index].selected = !this.statusList[index].selected
      this.resetPaginationValues = true
      this.getInvoicesList()
    },
    getInvoicesList() {
      let isValid = true
      this.filterObject.statusList = []
      this.statusList.map((data) => {
        if (data.selected) {
          this.filterObject.statusList.push(data.statusId)
        }
      })
      if (this.filterObject.statusList.length === 0) {
        this.invoiceList = []
        isValid = false 
      }
      console.log("call getInvoicesList", this.$route);
      if (isValid) {
        this.$store.dispatch("SetLoader", {status: true,message: "Loading...",});
        ADMINAPI.getInvoiceList(
          this.filterObject.start,
          this.filterObject.length,
          this.filterObject.searchVal,
          this.filterObject.statusList,
          this.filterObject.sortBy,
          this.filterObject.sortOrder,
          '',
          '',
          this.orgId,
          parseInt(this.$route.params.customerId),
          (response) => {
            this.resetPaginationValues = false
            this.invoiceList = response.Data.tableRow !== null ? response.Data.tableRow  : []
            console.log("response getInvoicesList", response);
            this.$store.dispatch("SetLoader", { status: false, message: "" });
          },
          (err) => {
            this.$store.dispatch('SetAlert', {showAlert: true, message: err.message, color: 'error'})
            this.resetPaginationValues = false
            this.$store.dispatch("SetLoader", { status: false, message: "" });
          }
        );
      }
    },
  }
}
</script>
<style scoped>
.table_col {
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
</style>
